import Model from '@/dc-it/models/Model'
import Field from '@/dc-it/types/Field'
import MultipleMultimedia from '@/dc-it/types/MultipleMultimedia'
import Multimedia from '@/dc-it/models/Multimedia'

export default class MenuMultimedia extends Model {
    endpoint = 'menu_multimedia'

    related = ['multimedia']

    fields = [
      new Field('id', 'Código').disabled(),
      new Field('title_es', 'Título en español'),
      new Field('title_en', 'Título en inglés'),
      new Field('url_es', 'URL en español'),
      new Field('url_en', 'URL en inglés'),
      new MultipleMultimedia('multimedia', 'Imagen', Multimedia).hide().setLimit(1),
    ]

    clone = () => MenuMultimedia
}
